import "./Img.scss";

export function Img({ src, width, height }: { src: string; width?: string; height?: string }) {
	return (
		<img
			className="__sage-image-container"
			src={src}
			loading="lazy"
			style={{ width, height }}
		/>
	);
}
