import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, ImagePosition, LargeCard, PageLayout, SecondNav, Section, Spacer } from "@sage/shared";

export function DataGovernancePage() {
	useEffect(() => {
		document.title = "Data Governance | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/governance-banner.avif"}
				left={
					<BannerCard
						title={"Organization Governance"}
						tagline={"Data Security"}
						content={
							"Sage ensures that your data is protected not only from external threats through least access controls. Even Sage AI employees do not have access to your data unless explicitly shared."
						}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Personel",
						href: "#personel"
					},
					{
						text: "Organizational Security",
						href: "#organizational-security"
					},
					{
						text: "Internal Policies",
						href: "#internal-policies"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="personel">
					<LargeCard
						tagline={"Personel"}
						title={"Internal Personel Controls"}
						subtitle={'Sage ensures "least access controls" to protect from personel risk.'}
						content={
							'Sage employs "least access controls" such that no personel has complete access to any system. We maintain a strict barrier between user data and Sage AI employees.\n\nThese access controls extend to all current and future employees, and all current and future investors or advisors.'
						}
						image={"https://cdn.sageai.dev/website/assets/people-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="organizational-security">
					<LargeCard
						tagline={"Organizational Security"}
						title={"Organizational Security Policies"}
						subtitle={"Sage employees adhere to industry standard security policies."}
						content={
							"Employees of Sage AI must adhere to strict organizational security policies including multi-factor authentication and password rotation/complexity requirements.\n\nWhenever accessing Sage infrastructure, temporary session credentials must be used as to not leak critical long standing credentials or keys."
						}
						image={"https://cdn.sageai.dev/website/assets/people-writing-3.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="personel">
					<LargeCard
						tagline={"Internal Policies"}
						title={"Internal Controls & Policies"}
						subtitle={"Critical infrastructure is monitored and protected from internal misuse."}
						content={
							"Strict controls on access & deployment to critical production systems are in place. Sage employs automated vulnerability monitoring, automated software patching, and deployment protections through built in continuous integration systems.\n\nThese protections ensure that user availability is maximized, employees are unable to mistakenly cause outages or data loss, and external actors are not able to gain access to critical systems."
						}
						image={"https://cdn.sageai.dev/website/assets/dev-team-6.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
