import { ViewTransition } from "../../ViewTransition";
import "./VideoCard.scss";

export interface IVideoCardProps {
	video: string;
	thumbnail?: string;
	size?: string;
	borderColor?: string;
}

export function VideoCard({ video, thumbnail, size, borderColor }: IVideoCardProps) {
	return (
		<ViewTransition full>
			<div className={`__sage-video-card-container ${size}`}>
				<div className={`video-card-inner ${borderColor}`}>
					<video
						controls
						poster={thumbnail}
					>
						<source src={video} />
					</video>
				</div>
			</div>
		</ViewTransition>
	);
}
