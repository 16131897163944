import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, ImagePosition, LargeCard, PageLayout, SecondNav, Section, Spacer, Tabs } from "@sage/shared";

export function HowWeSecureYourDataPage() {
	useEffect(() => {
		document.title = "How We Secure Your Data | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/security-banner.avif"}
				size={"lg"}
				left={
					<BannerCard
						title={"Security: Our Three Layer Approach"}
						tagline={"Data Security"}
						content={
							"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security."
						}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Document Level",
						href: "#document-level"
					},
					{
						text: "Account Level",
						href: "#account-level"
					},
					{
						text: "System Level",
						href: "#system-level"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="document-level">
					<LargeCard
						tagline={"Document Level Security"}
						title={"Your Documents Locked Down"}
						subtitle={"Your sensitive data is locked behind your team encrypted authentication token."}
						content={
							"At Sage, security starts with the most sensitive that our system touches: your client data.\n\nThis air tight security layer ensures that other teams using the Sage ecosystem will never be able to access your team's confidential client data, or any of your team's data."
						}
						image={"https://cdn.sageai.dev/website/assets/sec-4.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<Tabs
						tabs={[
							{
								id: "auth",
								title: "Encrypted Coupling",
								content: (
									<LargeCard
										title={"Team Scoped Encrypted Coupling"}
										subtitle={"Every document uploaded locked and coupled to your team."}
										content={
											"Each document is protected from unauthorized access by coupling it with a team specific key that is encrypted within an authentication token specific to the team that uploaded the data."
										}
										image={"https://cdn.sageai.dev/website/assets/sec-2.avif"}
										imagePosition={ImagePosition.Left}
									/>
								)
							},
							{
								id: "storage",
								title: "Data Storage",
								content: (
									<LargeCard
										title={"Data Storage"}
										subtitle={"Your documents are keyed by team when uploaded to our data storage index."}
										content={
											"Once your document gets uploaded to our data storage index, it is prefixed by the team specific key. This means that to retrieve the information contained within the document, a user must provide the team key."
										}
										image={"https://cdn.sageai.dev/website/assets/database-1.avif"}
										imagePosition={ImagePosition.Right}
									/>
								)
							},
							{
								id: "retrieval",
								title: "Query Protection",
								content: (
									<LargeCard
										title={"Runtime Query Protection"}
										subtitle={
											"Team keys are retrieved by decrypting the requesting user's authentication token at query time."
										}
										content={
											"When a query is made to our data storage index, an authentication token must be present. This authentication token is retrieved when you log in to the Sage web application.\n\nThis authentication token is first decrypted and validated, ensuring that it has a valid signature and has not been tampered with.\n\nThen the team key is extracted from the token and connected to the search requested by the user, unlocking only your team's data."
										}
										image={"https://cdn.sageai.dev/website/assets/sec-3.avif"}
										imagePosition={ImagePosition.Left}
									/>
								)
							}
						]}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-notext-banner.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Sage AI Cloud Security"}
							content={"Have unanswered questions? Schedule a call to discuss how we keep your data secure."}
							actions={<Button href={"/get-started"}>Schedule</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="account-level">
					<LargeCard
						tagline={"Account Level Security"}
						title={"Industry Standard Account Security"}
						subtitle={"Your account's data is all secured using up to date security practices."}
						content={
							"All of your account data is protected by up to date application security standards. This includes industry standard encryption algorithms and identity providers. We rely on AWS Managed account security services because authentication is a core capability that needs signifigant capital expenditure in R&D."
						}
						image={"https://cdn.sageai.dev/website/assets/people-writing-2.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="system-level">
					<LargeCard
						tagline={"System Level Security"}
						title={"End to End System Security"}
						subtitle={"Sage is following industry best practices for end to end system security."}
						content={
							"All of your data is protected within secure AWS Cloud Managed Services using the latest best practices. This ensures that Sage is protected from both from direct and supply chain attacks.\n\nAWS has dedicated teams that monitor and patch vulnerabilities as soon as they are found and before they are exploited by bad actors."
						}
						image={"https://cdn.sageai.dev/website/assets/sec-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
