import { useEffect, useRef, useState } from "react";
import {
	Accent,
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	Carosel,
	CaroselItem,
	Col,
	FaIcon,
	Gutter,
	H1,
	H2,
	Hero,
	ImagePosition,
	LargeCard,
	MutliTextScroll,
	P,
	PageLayout,
	Ribon,
	Row,
	Schedule,
	Section,
	Spacer,
	VideoCard,
	ViewTransition
} from "@sage/shared";
import "./HomePage.scss";

const pageToColor = {
	"CIMs": "red",
	"Teasers": "teal",
	"Presentations": "yellow",
	"Financial Analysis": "green",
	"Charts & Graphs": "purple",
	"Information Requests": "blue",
	"Data Room Analysis": "green"
};

export function HomePage() {
	const [activeCaroselPage, setActiveCaroselPage] = useState("CIMs");
	const [videoBg, setVideoBg] = useState(pageToColor["CIMs"]);
	const intervalRef = useRef(null);

	function updateActiveCaroselPage(page: string) {
		clearInterval(intervalRef.current);
		setActiveCaroselPage(page);
		setVideoBg(pageToColor[page]);
	}

	function loopCarosel() {
		let idx = 0;
		let ids = [
			"CIMs",
			"Teasers",
			"Presentations",
			"Financial Analysis",
			"Charts & Graphs",
			"Information Requests",
			"Data Room Analysis"
		];
		return setInterval(() => {
			if (idx < ids.length - 1) {
				idx++;
			} else {
				idx = 0;
			}
			setActiveCaroselPage(ids[idx]);
			setVideoBg(pageToColor[ids[idx]]);
		}, 10000);
	}

	useEffect(() => {
		document.title = "Sage AI";
		intervalRef.current = loopCarosel();
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	return (
		<section className="__sage-home-page-container">
			<PageLayout size="wide">
				<Col
					horizontalAlign="center"
					width="100%"
					gap="2rem"
				>
					<Hero>
						Specialized AI for <br />
						<MutliTextScroll
							options={[
								"Investment Banks",
								"Creating CIMs",
								"Writing Teasers",
								"Presentations",
								"Financial Analysis",
								"Due Diligence",
								"Visualizations"
							]}
						/>
						<H2>
							Save <Accent>an hour</Accent> every day at any stage of the deal process. <Accent>Guaranteed.</Accent>
						</H2>
					</Hero>
					<Col
						gap="2.5rem"
						horizontalAlign="center"
						width={"100%"}
					>
						<Row gap={"1rem"}>
							<Button
								variant={"blue"}
								href="/examples"
							>
								Examples
							</Button>
							<Button
								variant={"dark"}
								href="#get-started"
							>
								Get Started
							</Button>
						</Row>
						<Ribon>
							<FaIcon
								icon={"file-chart-column"}
								size="2x"
								color={"#a80300"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "CIMs"}
								onClick={() => updateActiveCaroselPage("CIMs")}
							>
								CIMs/CIPs
							</FaIcon>
							<FaIcon
								icon={"memo"}
								size="2x"
								color={"#30abc6"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Teasers"}
								onClick={() => updateActiveCaroselPage("Teasers")}
							>
								Teasers
							</FaIcon>
							<FaIcon
								icon={"screen-users"}
								size="2x"
								color={"#f7a501"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Presentations"}
								onClick={() => updateActiveCaroselPage("Presentations")}
							>
								Presentations
							</FaIcon>
							<FaIcon
								icon={"file-spreadsheet"}
								size="2x"
								color={"#00a857"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Financial Analysis"}
								onClick={() => updateActiveCaroselPage("Financial Analysis")}
							>
								Financial Analysis
							</FaIcon>
							<FaIcon
								icon={"chart-pie-simple-circle-dollar"}
								size="2x"
								color={"#5700a8"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Charts & Graphs"}
								onClick={() => updateActiveCaroselPage("Charts & Graphs")}
							>
								Charts & Graphs
							</FaIcon>
							<FaIcon
								icon={"ballot-check"}
								size="2x"
								color={"#0051a8"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Information Requests"}
								onClick={() => updateActiveCaroselPage("Information Requests")}
							>
								Information Requests
							</FaIcon>
							<FaIcon
								icon={"folder-tree"}
								size="2x"
								color={"#00a857"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Data Room Analysis"}
								onClick={() => updateActiveCaroselPage("Data Room Analysis")}
							>
								Data Room Analysis
							</FaIcon>
						</Ribon>
						<Carosel activePage={activeCaroselPage}>
							<CaroselItem
								id="CIMs"
								backgroundColor={"#e3adac"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-cim-ts-3.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<Col gap={"0.5rem"}>
												<H1>Create CIM/CIP Drafts In Minutes </H1>
											</Col>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"person-running-fast"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Build CIMs and CIPs drafts in minutes using an intuitive AI user interface connected
													directly to the data room.
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-waterfall"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Pick your slide elements and let AI fill them with data room information and charts that
													fit your firm’s exact output.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Teasers"
								backgroundColor={"#c0e5ee"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-teaser.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Create Comprehensive Teasers</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"business-time"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Create anonymized teasers in minutes by pulling data straight from your data room and
													past deals.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Presentations"
								backgroundColor={"#fde4b2"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-presentations.avif"} />
										</ViewTransition>
									</div>

									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Create Management Presentation Drafts Fast</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"print"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Create compelling management decks in minutes using your templates.</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-pie"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Add charts and analysis directly from your data room to tell your client's story.</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Financial Analysis"
								backgroundColor={"#ace3c8"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-financial-analysis.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Perform Complex Financial Analysis</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"layer-group"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Turn messy financial data into clear insights and charts in seconds.</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"table-layout"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Get instant analysis for CIMs, models, and management decks without touching Excel.</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Charts & Graphs"
								backgroundColor={"#c8ace3"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-charts-graphs.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Create Complex Charts & Graphs</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-line-up"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Get instant answers to buyer questions with charts and data straight from your data
													room.
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-network"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Just ask in plain English - 'Show me revenue by customer' - and Sage creates the perfect
													visualization.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Information Requests"
								backgroundColor={"#acc6e3"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-info-request.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Build precise information requests in minutes.</H1>
											<P>Build your info request list in minutes, not hours. </P>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"list-check"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Sage removes irrelevant items and adds important deal-specific requests automatically.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Data Room Analysis"
								backgroundColor={"#ace3c8"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-data-room-ts.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Find Any Information in the Data Room In Seconds</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"person-running-fast"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Find what you need instantly in any data room - even if you have thousands of files. </P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-waterfall"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>You can even create things like charts, graphs, and tables.</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="#get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
						</Carosel>
					</Col>
				</Col>
			</PageLayout>
			<PageLayout>
				<VideoCard
					video={"https://sageai.dev/cdn/website/assets/sage-vsl.mp4"}
					thumbnail={"https://cdn.sageai.dev/website/assets/home-page-thumbnail-2.avif"}
					size={"medium"}
					borderColor={videoBg}
				/>
				<LargeCard
					tagline="No Hastle Setup"
					title={
						<>
							From Signup to CIM Slides in <Accent>30 Minutes</Accent> or Less
						</>
					}
					subtitle={"No extensive setup or training required - just instant efficiency for your deal team. "}
					content={
						"Simply connect your data room and start getting answers in seconds. Whether it’s 20 files or 2000 files, Sage will find what you’re looking for in seconds.\n\nOur intuitive interface lets you hit the ground running. Pull key information, draft complex slides, and answer diligence questions."
					}
					image={"https://cdn.sageai.dev/website/assets/home-page-charts-graphs.avif"}
					imagePosition={ImagePosition.Right}
					imageBorder="none"
				/>
				<Section name="get-started">
					<Row
						horizontalAlign="center"
						width={"100%"}
					>
						<Col
							gap={"1rem"}
							maxWidth={"800px"}
						>
							<H2>
								Try <Accent>Sage AI</Accent> Today
							</H2>
							<P>
								Sage AI’s system is designed to get results.{" "}
								<Accent>
									<strong>Fast.</strong>
								</Accent>
							</P>
							<P>Our intuitive UI allows you to get client ready results in less than 30 minutes of signing up.</P>
							<P>
								No extensive set ups or training required. Just simply meet with Brendan to get a trial account created this
								afternoon and start saving time today.
							</P>
							<P></P>
							<H2>Do you want to see some examples?</H2>
							<P>Check out our page of examples to see some common use cases before you schedule a call</P>
							<Button
								variant={"blue"}
								href="/examples"
							>
								Examples
							</Button>
						</Col>
					</Row>
					<Schedule />
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/home-page-banner-4.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Trusted by Investment Banks across 11 countries"}
							color="dark"
							content={<img src="https://cdn.sageai.dev/website/assets/globe.avif" />}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<CardList
					cards={[
						{
							title: "Managing Director, Lower Middle Market Investment Bank",
							content:
								'"It’s unbelievable, actually. Sage has been such a game changer since we figured out how to implement it into everyday processes, we did not realize how inefficient doing everything manually actually was."',
							padding: "2rem"
						},
						{
							title: "Vice President, Middle Market Investment Bank",
							content:
								'"Look, as somebody who has their feet on the ground, Sage fit into our process surprisingly well starting on the first day."',
							padding: "2rem"
						},
						{
							title: "Director, Boutique Investment Bank",
							content:
								'"Now that our team is thinking through the AI lense for creating CIMs or answering diligence questions, they’re able to work so much more efficiently than we realized was even possible."',
							padding: "2rem"
						},
						{
							title: "Vice President, Lower Middle Market Investment Bank",
							content:
								'"We have a small team of only 4 bankers so I was skeptical to have our team use Sage at first, but now it’s helping us work so much more efficiently when times get busy we don’t have to work nearly as many late nights."',
							padding: "2rem"
						},
						{
							title: "Partner, Boutique Investment Bank",
							content:
								'"The most arduous part of our deals was running diligence with owners who had disorganized or limited data, but Sage now takes most of that weight off our back."',
							padding: "2rem"
						},
						{
							title: "Partner, Lower Middle Market Investment Bank",
							content:
								'"I thought Sage was going to be a complete pain to get my team to start using, as we’re pretty slow to embrace new technology, but this has been a complete game changer for our team."',
							padding: "2rem"
						}
					]}
				/>
				<LargeCard
					tagline="Security"
					title={
						<>
							Safe AI with <Accent>enterprise-grade</Accent> security at every level
						</>
					}
					content={
						"Enterprise-grade encryption protects your data, models, and proprietary knowledge.\n\nDedicated infrastructure ensures complete data isolation between clients.\n\nSage AI is the only Generative AI platform purpose-built for investment banking, Sage AI offers unparalleled security for your firm’s most valuable assets - your data and your people. "
					}
					image={"https://cdn.sageai.dev/website/assets/sec-2.avif"}
					imagePosition={ImagePosition.Left}
				/>
				<Accordian
					title={"Frequently Asked Questions"}
					hideImg
					sections={[
						{
							title: "How Does Sage AI Keep Everything 100% Private?",
							id: "privacy",
							content:
								"Sage AI implements enterprise-grade security at every level to ensure your data remains completely private. All data is encrypted both in transit and at rest using AWS infrastructure, with no third-party vendors involved.\n\nOur multi-tenant architecture ensures complete data isolation between clients through mandatory team ID authentication, meaning your firm's data can never be accessed by or mixed with other clients' information. We do not train our models on client data, and any support access requires explicit permission from your team.\n\nAs a tools company rather than a models company, our business model is built around protecting your privacy while helping you work more efficiently."
						},
						{
							title: "How Do You Ensure Data Is Not Used for Training?",
							id: "training",
							content:
								"At Sage AI, we are fundamentally a tools company, not a models company. This means we have no business incentive to train on your data - our goal is to provide you with powerful tools, not to build better AI models.\n\nWe maintain strict technical controls that prevent your data from being used for training: all information is siloed by company, encrypted end-to-end, and only accessible via secure authentication tokens. Any model training would require explicit written agreements, which we do not seek or encourage.\n\nUnlike general AI companies that may train on user data to improve their models, our business is built around helping investment banks work more efficiently while maintaining absolute confidentiality of their deal information. Your data is used solely to help you complete your work - nothing more."
						},
						{
							title: "How Do You Ensure Zero Chance of Data Breaches?",
							id: "data-breaches",
							content:
								"At Sage AI, we ensure zero chance of data breaches through multiple layers of enterprise-grade security. We use AWS's secure infrastructure with end-to-end encryption, strict data isolation between clients through mandatory team ID authentication, and no third-party vendor access.\n\nAll data is encrypted both in transit and at rest using industry-standard protocols. Our engineering team, with backgrounds from pioneers of modern internet infrastructure, has built security into every aspect of our platform.\n\nWe employ active security monitoring, and regularly review our security measures. For complete transparency, we're happy to work directly with your compliance team to explain our comprehensive security protocols in detail."
						},
						{
							title: "How Do You Ensure Safe and Accurate Responses?",
							id: "accuracy",
							content:
								"At Sage AI, we ensure safe and accurate responses by only using information from your connected data sources - never external websites or generic AI knowledge. When you ask a question, Sage searches solely through your data room, internal documents, and verified company websites that you've explicitly connected.\n\nEvery response is backed by direct citations to source documents, which you can instantly verify with a single click. If information isn't available in your connected sources, Sage will tell you directly rather than making assumptions. We also maintain strict version control of all documents, so you can track exactly where each piece of information originated. This source-based approach ensures that every response is accurate, verifiable, and based purely on your firm's trusted data."
						},
						{
							title: "What Does it Cost?",
							id: "cost",
							content:
								"Our pricing starts at just $80 per user per month, making Sage AI accessible for investment banks of all sizes. Each plan is customized to your firm's specific needs, volume of deals, and number of users. To get a detailed pricing breakdown and learn about volume discounts for your team, schedule a quick 10-minute call with us today. We'll walk you through how we can tailor our platform to maximize value for your specific use case."
						},
						{
							title: "How Long Can I Try It For?",
							id: "trial",
							content:
								"Our trial periods are customized to match your team's needs and deal timeline. Rather than a fixed trial period, we work with each firm to ensure you have enough time to properly evaluate Sage AI on actual deal work. This could mean testing it on a current transaction, analyzing a past deal, or setting up templates for upcoming projects. Schedule a quick call with us to discuss your specific requirements and we'll design a trial that makes sense for your team."
						},
						{
							title: "I only do 1-5 deals per year, I wont use it that much.",
							id: "small",
							content:
								"Even with a smaller deal volume, Sage AI delivers significant value by dramatically reducing the time spent on each transaction. Whether you're working on one deal or twenty, tasks like analyzing data rooms, creating CIMs, and responding to buyer questions still consume dozens of hours per deal.\n\nOur platform helps you complete these tasks in a fraction of the time, letting you focus on high-value activities like client relationships and deal strategy. Plus, Sage AI's capabilities extend beyond just deal execution - you can use it for prospect research, pitch materials, and market analysis between transactions.\n\nMany of our users with similar deal volumes report that Sage pays for itself just by eliminating late nights and weekend work on their first deal."
						},
						{
							title: "My slides don't look like the ones Sage produces...",
							id: "slides",
							content:
								"While it's true that you'll need to format slides according to your firm's exact templates and branding, Sage AI saves you time where it matters most: gathering and organizing information.\n\nTypically, over half the time spent creating deal materials goes into finding relevant data, analyzing financials, verifying facts, and structuring content - not formatting slides. Sage AI eliminates these time-consuming tasks by instantly pulling all pertinent information from your data room, organizing it logically, and presenting it in a clear format that's easily transferable to your slides.\n\nThe platform also provides:\n\n - Accurate financial data and calculations\n\n - Ready-to-use charts and graphs\n\n - Pre-written content that can be quickly customized\n\n - Direct citations for every data point\n\n - Exportable tables and figures\n\nThis means you start with comprehensive, well-structured content instead of a blank page - then simply apply your firm's formatting standards to create polished, client-ready materials."
						}
					]}
				/>
			</PageLayout>
			<PageLayout>
				<H2>Want to learn more?</H2>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Schedule a Demo",
							image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
							content: "Developed from M&A processes at industry leading firms to address real-world challenges.",
							href: "#get-started",
							cta: "Schedule"
						},
						{
							title: "Examples",
							image: "https://cdn.sageai.dev/example-videos/1-business-description/thumbnail.png",
							content: "Check out some example videos of common tasks within Sage",
							href: "/examples",
							cta: "Watch"
						},
						{
							title: "Sage AI Data Security",
							content:
								"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
							href: "/security/how-we-secure-your-data",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
