import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, ImagePosition, Img, LargeCard, PageLayout, SecondNav, Section, Spacer } from "@sage/shared";

export function DeliverableCreationPage() {
	useEffect(() => {
		document.title = "Deliverable Creation | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/home-page-banner-4.avif"}
				size={"lg"}
				left={
					<BannerCard
						color={"dark"}
						title={"Precision-Engineered Deliverables"}
						tagline={"SAGE AI"}
						content={
							"Accelerate your deal flow and reduce time-to-market with Sage AI's advanced document creation tools.\n\nOur platform empowers your team to produce superior CIPs, CIMs, and other critical deliverables, giving you a competitive edge in the fast-paced world of investment banking."
						}
						actions={
							<Button
								variant={"dark"}
								href="/get-started"
							>
								Get Started
							</Button>
						}
					/>
				}
				right={<Img src="https://cdn.sageai.dev/website/assets/3-devices.png" />}
			/>
			<SecondNav
				links={[
					{
						text: "Overview",
						href: "#overview"
					},
					{
						text: "Templates",
						href: "#templates"
					},
					{
						text: "Source Attribution",
						href: "#source-attribution"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="Overview">
					<LargeCard
						tagline={"Overview"}
						title={"AI-Powered Precision in Investment Banking"}
						subtitle={"Revolutionize Your Document Creation Process"}
						content={
							"Sage AI combines cutting-edge artificial intelligence with deep industry knowledge to transform how investment banks create critical documents.\n\nOur platform offers intelligent templates, meticulous source attribution, and interactive data room exploration, setting a new standard for efficiency and accuracy in the field."
						}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Smart Templates",
							subtitle: "Your Expertise, Amplified",
							image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
							content:
								"Sage AI learns from your past successes, creating tailored templates that capture your unique style and best practices.\n\nThese adaptive templates evolve with your needs, ensuring consistent quality while dramatically reducing document creation time.",
							href: "#templates"
						},
						{
							title: "Bulletproof Attribution",
							subtitle: "Every Fact, Traced to Source",
							image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
							content:
								"Our AI provides comprehensive source attribution, linking every piece of information in your documents back to its origin in the data room.\n\nThis meticulous approach enhances credibility, streamlines due diligence, and instills confidence in your deliverables.",
							href: "#source-attribution"
						},
						{
							title: "Interactive Data Room Analysis",
							subtitle: "Unlock Hidden Insights",
							image: "https://cdn.sageai.dev/website/assets/docs-project-files-close-up-1.avif",
							content:
								"Sage AI doesn't just read your data room; it understands it. Our platform allows you to interactively explore and query your data, uncovering valuable insights and connections that might otherwise be missed.\n\nThis deep analysis capability ensures you leverage all available information for superior document creation.",
							href: "/solutions/information-retrieval"
						}
					]}
				/>
			</Spacer>
			<PageLayout>
				<Section name="templates">
					<LargeCard
						tagline="templates"
						title={"Customized Efficiency"}
						subtitle={"Your Past Success, Your Future Template"}
						content={
							"Sage AI meticulously analyzes your previous high-performing CIMs and CIPs to create tailored templates that encapsulate your expertise.\n\nThese intelligent templates capture your unique style, industry insights, and best practices, ensuring consistency and quality across all future documents.\n\nBy learning from your past work, our system helps you maintain your high standards while significantly reducing document creation time. This approach not only streamlines your workflow but also allows your team to focus on high-value tasks, enhancing overall productivity."
						}
						image={"https://cdn.sageai.dev/website/assets/templates-modal-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<LargeCard
						title={"Adaptive Intelligence"}
						subtitle={"Templates That Evolve With Your Needs"}
						content={
							"Our template system goes beyond simple replication - it understands context and adapts intelligently.\n\nAs you continue to use Sage AI, it continually refines your templates based on new successful documents you create, incorporating the latest best practices.\n\nThis adaptive approach ensures your templates stay current with industry trends and your evolving preferences, providing a constantly optimized foundation for your work.\n\nThe result is a dynamic system that grows with your business, consistently delivering relevant, up-to-date templates that reflect your firm's latest standards and market positioning."
						}
						image={"https://cdn.sageai.dev/website/assets/templates-with-sources.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-notext-banner.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Transform Your Document Creation Today"}
							content={
								"Ready to harness the power of AI-driven templates?\n\nSchedule a call to see how Sage AI can streamline your CIM and CIP production process, saving you time and ensuring consistent quality across all your deliverables."
							}
							actions={<Button href={"/get-started"}>Get Started</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="source-attribution">
					<LargeCard
						tagline="Source Attribution"
						title={"Uncompromising Accuracy"}
						subtitle={"Every Fact Traced to Its Source"}
						content={
							"Sage AI sets a new standard for reliability in document creation with its robust source attribution system.\n\nEvery piece of information used in your CIMs and CIPs is automatically linked back to its origin in the data room.\n\nThis meticulous approach ensures that all claims and figures in your documents are verifiable, enhancing credibility and trust.\n\nBy providing a clear trail of evidence, Sage AI not only boosts confidence in your deliverables but also streamlines due diligence processes, saving valuable time and resources."
						}
						image={"https://cdn.sageai.dev/website/assets/athena-source-attribution.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<LargeCard
						title={"Transparent Intelligence"}
						subtitle={"AI-Powered Insights with Human-Readable Proof"}
						content={
							"Our AI doesn't just provide information - it shows its work. Sage AI's source attribution feature creates a bridge between AI-generated content and human-verified data, offering the best of both worlds.\n\nAs the system compiles your documents, it simultaneously builds a comprehensive citation network, linking each data point to specific files or sections within your data room.\n\nThis transparency allows for quick fact-checking, enables easier collaborative review, and instills confidence in your team and clients alike.\n\nIt's not just about having the right information; it's about knowing exactly where it came from."
						}
						image={"https://cdn.sageai.dev/website/assets/athena-with-sources.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Sage AI Data Security",
							content:
								"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
							href: "/security/how-we-secure-your-data",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
						},
						{
							title: "Examples",
							image: "https://cdn.sageai.dev/example-videos/1-business-description/thumbnail.png",
							content: "Check out some example videos of common tasks within Sage",
							href: "/examples",
							cta: "Watch"
						},
						{
							title: "Sage AI Augments Each Stage of the Deal Cycle",
							image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
							content:
								"Sage AI was meticulously designed with a deep understanding of the daily challenges faced investment banking staff members.",
							href: "/solutions/investment-banking"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
