import { useEffect } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { usePostHog } from "posthog-js/react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Layout } from "@sage/shared";
import { ExamplesPage } from "./Components/Pages/Examples";
import { GetStartedPage } from "./Components/Pages/GetStartedPage";
import { HomePage } from "./Components/Pages/HomePage";
import { AnswersYouCanTrustPage } from "./Components/Pages/ResponsibleAI/AnswersYouCanTrustPage";
import { DataTrainingAndPrivacyPage } from "./Components/Pages/ResponsibleAI/DataTrainingAndPrivacyPage";
import { PrinciplesAndApproachPage } from "./Components/Pages/ResponsibleAI/PrinciplesAndApproachPage";
import { ResponsibleAIOverviewPage } from "./Components/Pages/ResponsibleAI/ResponsibleAIOverviewPage";
import { DataGovernancePage } from "./Components/Pages/Security/DataGovernancePage";
import { HowWeSecureYourDataPage } from "./Components/Pages/Security/HowWeSecureYourDataPage";
import { SecurityPolicyPage } from "./Components/Pages/Security/SecurityPolicyPage";
import { DeliverableCreationPage } from "./Components/Pages/Solutions/DeliverableCreationPage";
import { EquityResearchPage } from "./Components/Pages/Solutions/EquityResearchPage";
import { InformationRetrievalPage } from "./Components/Pages/Solutions/InformationRetrievalPage";
import { InvestmentBankingPage } from "./Components/Pages/Solutions/InvestmentBankingPage";
import { PrivateEquityPage } from "./Components/Pages/Solutions/PrivateEquityPage";

export function Router() {
	const fp = useVisitorData({ timeout: 30000, extendedResult: true });
	const posthog = usePostHog();

	useEffect(() => {
		if (!fp.isLoading && fp.data) {
			posthog.identify(fp.data.visitorId, { fp: fp.data });
		}
	}, [fp.data, fp.isLoading, fp.error]);

	return (
		<RouterProvider
			future={{ v7_startTransition: false }}
			router={createBrowserRouter([
				{
					path: "",
					element: <Layout />,
					children: [
						{
							path: "",
							element: <HomePage />
						},
						{
							path: "examples",
							element: <ExamplesPage />
						},
						{
							path: "solutions",
							children: [
								{
									path: "deliverable-creation",
									element: <DeliverableCreationPage />
								},
								{
									path: "information-retrieval",
									element: <InformationRetrievalPage />
								},
								{
									path: "investment-banking",
									element: <InvestmentBankingPage />
								},
								{
									path: "private-equity",
									element: <PrivateEquityPage />
								},
								{
									path: "equity-research",
									element: <EquityResearchPage />
								}
							]
						},
						{
							path: "security",
							children: [
								{
									path: "how-we-secure-your-data",
									element: <HowWeSecureYourDataPage />
								},
								{
									path: "security-and-privacy-policy",
									element: <SecurityPolicyPage />
								},
								{
									path: "governance",
									element: <DataGovernancePage />
								}
							]
						},
						{
							path: "responsible-ai",
							children: [
								{
									path: "overview",
									element: <ResponsibleAIOverviewPage />
								},
								{
									path: "answers-you-can-trust",
									element: <AnswersYouCanTrustPage />
								},
								{
									path: "data-training-and-privacy",
									element: <DataTrainingAndPrivacyPage />
								},
								{
									path: "principles-and-approach",
									element: <PrinciplesAndApproachPage />
								}
							]
						},
						{
							path: "get-started",
							element: <GetStartedPage />
						},
						{
							path: "*",
							element: (
								<Navigate
									to="/"
									replace
								/>
							)
						}
					]
				}
			])}
		/>
	);
}
