import React, { ReactNode } from "react";
import "./Row.scss";

export function Row({
	children,
	gap,
	height,
	horizontalAlign,
	padding,
	paddingBottom,
	paddingInline,
	paddingTop,
	verticalAlign,
	wrap,
	scroll,
	width
}: {
	children: ReactNode;
	gap?: string;
	height?: string;
	horizontalAlign?: string;
	padding?: string;
	paddingBottom?: string;
	paddingInline?: string;
	paddingTop?: string;
	verticalAlign?: string;
	wrap?: boolean;
	scroll?: boolean;
	width?: string;
}) {
	return (
		<div
			className={`__sage-row-container ${wrap === false ? "nowrap" : ""} vertical-${verticalAlign} horizontal-${horizontalAlign} ${scroll ? "scroll" : ""}`}
			style={{ height, paddingInline, gap, padding, paddingTop, paddingBottom, width }}
		>
			{children}
		</div>
	);
}
