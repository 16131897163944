import { ReactElement, useState } from "react";
import { Collapsible } from "../Collapsible";
import { ViewTransition } from "../ViewTransition";
import "./Accordian.scss";

export interface IAccordianProps {
	title?: string;
	subtitle?: string;
	content?: string;
	sections?: ISection[];
	hideImg?: boolean;
}

interface ISection {
	id: string;
	title?: string;
	content?: string;
	image?: string;
	actions?: ReactElement;
}

export function Accordian({ title, subtitle, content, sections, hideImg }: IAccordianProps) {
	const [activeSection, setActiveSection] = useState<ISection>(sections.length ? sections[0] : null);

	return (
		<div className="__sage-accordian-container">
			<div className="header">
				{title && <div className="title">{title}</div>}
				{subtitle && <div className="subtitle">{subtitle}</div>}
				{content && <div className="content">{content}</div>}
			</div>
			<div className="body">
				<div className="col">
					{sections?.map((section) => (
						<ViewTransition
							full
							key={section.id}
						>
							<div className={`section ${section.id === activeSection?.id ? "active" : ""} ${hideImg ? "full" : ""}`}>
								<Collapsible
									visible={section.id === activeSection?.id}
									toggle={() => setActiveSection(section)}
									toggleHandle={<div className="section-title">{section.title}</div>}
								>
									<div className="section-content">{section.content}</div>
									<div className="section-actions">{section.actions}</div>
								</Collapsible>
							</div>
						</ViewTransition>
					))}
				</div>
				{!hideImg && (
					<div className="col">
						<img
							src={activeSection?.image}
							loading="lazy"
						/>
					</div>
				)}
			</div>
		</div>
	);
}
