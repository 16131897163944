import { ReactElement } from "react";
import "./BannerCard.scss";

export interface IBannerCardProps {
	title?: string;
	tagline?: string;
	content?: string;
	actions?: ReactElement;
	color?: string;
	variant?: string;
}

export function BannerCard({ title, tagline, content, actions, color, variant }: IBannerCardProps) {
	return (
		<div className={`__sage-banner-card-container ${color ?? ""} ${variant ?? ""}`}>
			<div className="card-body">
				{tagline && <div className="tagline">{tagline}</div>}
				{title && <div className="title">{title}</div>}
				{content && <div className="content">{content}</div>}
				{actions && <div className="actions">{actions}</div>}
			</div>
		</div>
	);
}
