import { ReactNode, useEffect, useState } from "react";
import { ViewTransition } from "../ViewTransition";
import "./Text.scss";

export function Hero({ children }: { children: ReactNode }) {
	return (
		<ViewTransition width={"100%"}>
			<div className="__sage-text __sage-hero-text-container">{children}</div>
		</ViewTransition>
	);
}

export function H1({ children }: { children: ReactNode }) {
	return (
		<ViewTransition>
			<div className="__sage-text __sage-h1-container">{children}</div>
		</ViewTransition>
	);
}

export function H2({ children, textAlign }: { children: ReactNode; textAlign?: string }) {
	return (
		<ViewTransition>
			<div
				className="__sage-text __sage-h2-container"
				style={{ textAlign }}
			>
				{children}
			</div>
		</ViewTransition>
	);
}

export function H3({ children }: { children: ReactNode }) {
	return (
		<ViewTransition>
			<div className="__sage-text __sage-h3-container">{children}</div>
		</ViewTransition>
	);
}

export function P({ children, inline }: { children: ReactNode; inline?: boolean }) {
	return (
		<ViewTransition>
			<div className={`__sage-text __sage-p-container ${inline ? "inline" : ""}`}>{children}</div>
		</ViewTransition>
	);
}

export function Text({ children }: { children: ReactNode }) {
	return (
		<ViewTransition>
			<div className="__sage-text __sage-text-container">{children}</div>
		</ViewTransition>
	);
}

export function MutliTextScroll({ options }: { options: string[] }) {
	const [belowOption, setBelowOption] = useState(1);
	const [inlineOption, setInlineOption] = useState(0);
	const [aboveOption, setAboveOption] = useState(options.length - 1);

	useEffect(() => {
		if (options.length) {
			const loop = setInterval(() => {
				console.log("loop");
				setInlineOption((i) => {
					let newInline = i + 1;
					let newAbove = i;
					let newBelow = i + 2;

					if (newInline >= options.length) {
						newInline = 0;
						newBelow = 1;
					}

					setBelowOption(newBelow);
					setAboveOption(newAbove);
					return newInline;
				});
			}, 5000);
			return () => {
				clearInterval(loop);
			};
		}
	}, [options]);

	return (
		<div className="__sage-text-multi-text-scroll">
			{options.map((option, idx) => (
				<div
					className={`inner ${inlineOption === idx ? "inline" : aboveOption === idx ? "above" : "below"}`}
					key={option}
				>
					{option}
				</div>
			))}
		</div>
	);
}

export function Accent({ children }: { children: ReactNode }) {
	return <span className="__sage-text-accent">{children}</span>;
}
