import { useEffect } from "react";
import {
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	ImagePosition,
	LargeCard,
	PageLayout,
	SecondNav,
	Section,
	Spacer
} from "@sage/shared";

export function EquityResearchPage() {
	useEffect(() => {
		document.title = "Equity Research | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/equity-research-banner.avif"}
				left={
					<BannerCard
						title={"AI-Enhanced Equity Research"}
						tagline={"SAGE AI"}
						content={"Empowering Analysts with Deeper Insights and Faster Research"}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Overview",
						href: "#overview"
					},
					{
						text: "Research Efficiency",
						href: "#research-efficiency"
					},
					{
						text: "Uncovering Market Trends",
						href: "#market-trends"
					},
					{
						text: "Report Generation",
						href: "#report-generation"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="overview">
					<LargeCard
						tagline={"Overview"}
						title={"AI-Powered Equity Research Insights"}
						subtitle={"Financial Analysis and Market Intelligence"}
						content={
							"Sage AI transforms equity research by harnessing the power of advanced language processing and machine learning.\n\nOur platform rapidly analyzes vast amounts of financial data, news, and market reports, enabling analysts to uncover deeper insights, identify trends, and generate more comprehensive research in less time.\n\nElevate your equity research capabilities and stay ahead in a fast-paced market with Sage AI's cutting-edge technology."
						}
						image={"https://cdn.sageai.dev/website/assets/people-writing-3.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="research-efficiency">
					<LargeCard
						tagline={"Research Efficiency"}
						title={"Enhancing Research Efficiency"}
						subtitle={"Accelerate Your Analysis with AI-Driven Insights"}
						content={
							"Sage AI streamlines the equity research process, allowing analysts to focus on high-value tasks and strategic thinking. Our LLM RAG platform processes and synthesizes information from diverse sources, providing rapid access to relevant data and insights."
						}
						image={"https://cdn.sageai.dev/website/assets/people-reading-documents-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<CardList
						cards={[
							{
								title: "Rapid Information Retrieval",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-1.avif",
								content:
									"Quickly access relevant information from financial reports, news articles, and market analyses using advanced natural language processing."
							},
							{
								title: "Automated Data Synthesis",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-2.avif",
								content:
									"Seamlessly integrate data from multiple sources, reducing manual compilation time and potential for human error."
							},
							{
								title: "Intelligent Summarization",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-3.avif",
								content: "Generate concise summaries of lengthy reports and documents, highlighting key points and trends."
							},
							{
								title: "Customized Research Focuses",
								image: "https://cdn.sageai.dev/website/assets/research-papers-2.avif",
								content:
									"Tailor AI-driven analyses to specific industries, companies, or market segments, aligning with your research priorities."
							}
						]}
					/>
				</Section>
				<Section name="market-trends">
					<LargeCard
						tagline={"Market Trends"}
						title={"Uncovering Hidden Market Trends"}
						subtitle={"Gain a Competitive Edge with AI-Driven Market Intelligence"}
						content={
							"Sage AI's advanced analytics capabilities help equity researchers identify emerging trends and subtle market shifts that might be missed by traditional analysis methods.\n\nBy processing vast amounts of qualitative data from diverse sources, our platform provides unique insights that can inform investment strategies and recommendations."
						}
						image={"https://cdn.sageai.dev/website/assets/markets-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<Accordian
						sections={[
							{
								id: "1",
								title: "Sentiment Analysis",
								content:
									"Gauge market sentiment by analyzing social media, news articles, and company communications across multiple languages.",
								image: "https://cdn.sageai.dev/website/assets/ib-page-docs-1.avif"
							},
							{
								id: "3",
								title: "Early Signal Detection",
								content:
									"Spot emerging trends or potential market disruptors before they become widely recognized, providing a first-mover advantage.",
								image: "https://cdn.sageai.dev/website/assets/home-page-dealstream-1.avif"
							},
							{
								id: "4",
								title: "Contextual Understanding",
								content:
									"Leverage our AI's ability to grasp complex relationships in qualitative data, providing nuanced insights into market dynamics.",
								image: "https://cdn.sageai.dev/website/assets/athena-close-up-card.avif"
							}
						]}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Elevate Your Market Intelligence"}
							content={
								"Experience the power of AI-driven trend analysis in your equity research.\n\nSchedule a call today to see how Sage AI can uncover hidden market insights for your team"
							}
							actions={<Button href={"/get-started"}>Get Started</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="report-generation">
					<LargeCard
						tagline={"Report Generation"}
						title={"Streamlining Report Generation"}
						subtitle={"Accelerate Your Research Output with AI Assistance"}
						content={
							"Sage AI transforms the report writing process, enabling equity researchers to produce comprehensive, data-driven reports more efficiently.\n\nOur platform assists in compiling relevant information, structuring content, and even generating initial drafts, allowing analysts to focus on adding high-value insights and recommendations."
						}
						image={"https://cdn.sageai.dev/website/assets/cim-3-devices.png"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Sage AI Data Security",
							content:
								"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
							href: "/security/how-we-secure-your-data",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
						},
						{
							title: "Interactive Data Room Analysis",
							image: "https://cdn.sageai.dev/website/assets/docs-project-files-close-up-1.avif",
							content:
								"Sage AI doesn't just read your data room; it understands it. Our platform allows you to interactively explore and query your data, uncovering valuable insights and connections that might otherwise be missed.",
							href: "/solutions/information-retrieval"
						},
						{
							title: "Supercharged Deliverable Creation",
							image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
							content:
								"The RAG-enabled LLM can generate tailored pitch materials that resonate with the specific needs and challenges of each prospect.\n\nThis includes personalized slides, and potential outcomes that demonstrate a deep understanding of the prospect's business and industry.",
							href: "/get-started"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
