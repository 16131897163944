import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, H1, H2, LargeCard, P, PageLayout, SecondNav, Section, Spacer, Text } from "@sage/shared";

export function DataTrainingAndPrivacyPage() {
	useEffect(() => {
		document.title = "Data Training & Privacy | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/data-policy-banner.avif"}
				left={
					<BannerCard
						title={"Safeguarding Your Data: Sage AI's Commitment to Privacy"}
						color={"dark"}
						tagline={"SAGE AI"}
						content={
							"Trust, Transparency, and Uncompromising Security: Discover How We Protect Your Sensitive M&A Data While Delivering AI-Powered Insights"
						}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Data Policy",
						href: "#data-policy"
					},
					{
						text: "Data Usage",
						href: "#data-usage"
					},
					{
						text: "Data Retention",
						href: "#data-retention"
					},
					{
						text: "Compliance",
						href: "#compliance"
					},
					{
						text: "Notifications",
						href: "#notification"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Text>
					<Section name="data-policy">
						<H1>Sage AI Data Privacy Policy</H1>
						<P>
							At Sage AI, we understand the critical importance of data privacy in the M&A and investment banking sectors. Our
							commitment to protecting your sensitive information is unwavering.
						</P>
						<P>This policy outlines our approach to data handling and privacy.</P>
					</Section>
					<Section name="data-usage">
						<H2>Data Usage and Protection:</H2>
						<P>
							Sage AI uses customer data solely for providing and improving our AI-powered M&A tools and services.{" "}
							<strong>
								We want to emphasize that we do not use customer data for training our AI models under any circumstances.
							</strong>
						</P>
						<P>
							Your data remains yours and is only processed to deliver the services you've requested. To ensure the utmost
							protection of your data, we employ industry-leading encryption standards to safeguard information both in
							transit and at rest.
						</P>
						<P>
							Access to customer data is strictly limited to essential personnel and is governed by robust access controls. We
							conduct regular security audits and penetration testing to maintain the integrity of our systems.
						</P>
					</Section>
					<Section name="data-retention">
						<H2>Data Retention and Transparency:</H2>
						<P>
							We retain customer data only for as long as necessary to provide our services or as required by law. You have
							the right to request deletion of your data at any time, subject to legal and contractual obligations.
						</P>
						<P>
							Transparency is a core value at Sage AI. We provide clear information about how your data is used within our
							services, and our AI models' outputs are accompanied by source attribution for full transparency.
						</P>
					</Section>
					<Section name="compliance">
						<H2>Compliance and Data Sharing:</H2>
						<P>
							Sage AI adheres to all applicable data protection laws and regulations. Our practices are regularly reviewed to
							ensure compliance with evolving legal requirements.{" "}
							<strong>We do not sell, rent, or share your data with third parties for marketing or other purposes.</strong>
						</P>
					</Section>
					<H2>Continuous Improvement and User Control:</H2>
					<P>
						While we don't use customer data for training, we continuously improve our AI models using publicly available
						information and synthetic datasets. We believe in giving you control over your data. You can request access,
						correction, or deletion of your information at any time.
					</P>
					<Section name="notification">
						<H2>Breach Notification and Contact Information:</H2>
						<P>
							In the unlikely event of a data breach, we commit to promptly notifying affected customers and relevant
							authorities. For any questions or concerns about our data privacy practices, please contact our dedicated
							privacy team at privacy@sageai.dev.
						</P>
					</Section>
					<H2>Commitment to Trust:</H2>
					<P>
						At Sage AI, customer trust is our top priority. We are committed to maintaining the highest standards of data
						privacy and security as we provide specialized AI tools for M&A-related use cases.
					</P>
					<P>
						This policy is subject to periodic review and updates. We encourage you to check this policy regularly for any
						changes.
					</P>
				</Text>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
