import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, ImagePosition, LargeCard, PageLayout, SecondNav, Section, Spacer, Tabs } from "@sage/shared";

export function ResponsibleAIOverviewPage() {
	useEffect(() => {
		document.title = "Responsible AI | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/responsible-ai-banner.avif"}
				left={
					<BannerCard
						title={"Responsible AI: The Cornerstone of Trust"}
						color={"dark"}
						tagline={"SAGE AI"}
						content={"Powering Financial Insights with Transparency, Privacy, and Ethical Integrity"}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Commitment to Responsible AI",
						href: "#commitment"
					},
					{
						text: "Source Attribution",
						href: "#source-attribution"
					},
					{
						text: "Data Privacy & Security",
						href: "#data-privacy-and-security"
					},
					{
						text: "Ethical AI Development",
						href: "#ethical-ai"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="commitment">
					<Tabs
						tabs={[
							{
								id: "1",
								title: "Responsible AI",
								content: (
									<LargeCard
										title={"Responsible AI at Sage"}
										subtitle={"Committed to Transparency, Privacy, and Ethical Practices"}
										content={
											"At Sage, we believe in the power of AI to transform investment banking. However, we recognize that with great power comes great responsibility.\n\nOur commitment to responsible AI is at the core of everything we do, ensuring that our technology is not only powerful but also ethical, transparent, and respectful of user privacy."
										}
										image={"https://cdn.sageai.dev/website/assets/people-working-1.avif"}
										imagePosition={ImagePosition.Right}
									/>
								)
							},
							{
								id: "2",
								title: "Transparent Citation",
								content: (
									<LargeCard
										title={"Transparent Citation"}
										subtitle={"Traceable Insights for Confident Decision-Making"}
										content={
											"Sage AI is designed to provide users with clear, traceable insights. Every piece of information our AI surfaces is accompanied by its source, allowing users to verify and dive deeper into the original context. This commitment to transparency ensures that our users can trust the insights provided and maintain the highest standards of due diligence in their work."
										}
										image={"https://cdn.sageai.dev/website/assets/research-papers-1.avif"}
										imagePosition={ImagePosition.Right}
									/>
								)
							},
							{
								id: "3",
								title: "Protecting Your Data",
								content: (
									<LargeCard
										title={"Protecting Your Data"}
										subtitle={"Your Information Stays Your Own"}
										content={
											"We have a strict policy of never training our AI models on user data. Your proprietary information and analyses remain yours alone. Sage AI is trained on publicly available information and curated datasets, ensuring that your sensitive data is never at risk of being incorporated into our models or shared with other users."
										}
										image={"https://cdn.sageai.dev/website/assets/data-security-people-1.avif"}
										imagePosition={ImagePosition.Right}
									/>
								)
							}
						]}
					/>
				</Section>
				<Section name="source-attribution">
					<LargeCard
						tagline={"Source Attribution"}
						title={"Source Attribution: Transparency in Every Insight"}
						subtitle={"Safeguarding Your Sensitive Information"}
						content={
							"At Sage, we understand the critical importance of data privacy in the financial sector. Our robust security measures ensure that your proprietary data remains protected at all times. We employ state-of-the-art encryption, strict access controls, and regular security audits to maintain the highest standards of data protection. Rest assured, your sensitive information is never used to train our AI models or shared with any third parties."
						}
						image={"https://cdn.sageai.dev/website/assets/person-using-athena-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="data-privacy-and-security">
					<LargeCard
						tagline={"Data Privacy & Security"}
						title={"Data Privacy: Our Top Priority"}
						subtitle={"Traceable Intelligence for Confident Decision-Making"}
						content={
							"Sage AI is built on the principle of transparency. Every insight, recommendation, or piece of information provided by our system is accompanied by clear source attribution.\n\nThis allows you to verify the origin of the data, understand its context, and conduct further research if needed.\n\nOur commitment to source attribution ensures that you can trust the outputs of our AI and maintain the highest standards of due diligence in your work."
						}
						image={"https://cdn.sageai.dev/website/assets/people-reading-documents-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="ethical-ai">
					<LargeCard
						tagline={"Ethical AI Development"}
						title={"Ethical AI Development"}
						subtitle={"Building AI with Integrity and Responsibility"}
						content={
							"At Sage, we are committed to developing AI systems that adhere to the highest ethical standards.\n\nOur development process incorporates regular ethical reviews, and ongoing monitoring for fairness and accountability.\n\nWe strive to create AI that not only enhances productivity but also promotes responsible practices in the financial sector."
						}
						image={"https://cdn.sageai.dev/website/assets/dev-team-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
