import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import "./Schedule.scss";

export function Schedule() {
	useCalendlyEventListener({
		onEventScheduled() {
			try {
				fbq("track", "Schedule"); // Facebook
			} catch (e) {
				console.error({ from: "Facebook", message: e });
			}
			try {
				gtag("event", "conversion", { send_to: "AW-16663024455/_rxVCLCyoMgZEMemxok-" }); // Google
			} catch (e) {
				console.error({ from: "Google", message: e });
			}
			try {
				window.lintrk("track", { conversion_id: 18131002 }); // Linkedin
			} catch (e) {
				console.error({ from: "Linkedin", message: e });
			}
		}
	});

	return (
		<div className="__sage-schedule-container">
			<InlineWidget
				url="https://calendly.com/brendan-oreilly/15-minute-meeting"
				styles={{ width: "100%", height: "50rem" }}
			/>
		</div>
	);
}
