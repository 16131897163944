import { useLocation } from "react-router-dom";
import { Button } from "../Button";
import { Dropdown } from "../Dropdown";
import "./SecondNav.scss";

export interface ISecondNavProps {
	links: {
		text: string;
		href: string;
	}[];
}

export function SecondNav({ links }: ISecondNavProps) {
	if (window.innerWidth > 1100) {
		return (
			<div className="__sage-second-nav-container">
				<div className="second-nav">
					{links.map((link) => (
						<a
							key={link.href}
							href={link.href}
							className="nav-section"
						>
							{link.text}
						</a>
					))}
					<div className="get-started">
						<Button
							href="/get-started"
							variant={"dark"}
						>
							Get Started
						</Button>
					</div>
				</div>
			</div>
		);
	} else {
		const { hash } = useLocation();

		return (
			<div className="__sage-second-nav-dropdown-container">
				<Dropdown
					title={links.find((l) => hash === l.href)?.text || links[0]?.text}
					size={"full"}
					options={links}
				/>
			</div>
		);
	}
}
