import { ReactNode, useRef, useState } from "react";
import { Button } from "../Button";
import { Col } from "../Col";
import { FaIcon } from "../FaIcon";
import { Row } from "../Row";
import { ViewTransition } from "../ViewTransition";
import "./VideoList.scss";

export function VideoList({ children }: { children: ReactNode }) {
	return (
		<Col
			gap={"2rem"}
			width="100%"
		>
			{children}
		</Col>
	);
}

export function VideoItem({ thumbnail, preview, src, title, description }) {
	const [mouseOver, setMouseOver] = useState(false);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const previewRef = useRef(null);
	const fullRef = useRef(null);

	function onMouseEnter() {
		if (!videoPlaying) {
			setMouseOver(true);
			previewRef.current.currentTime = 0;
			previewRef.current.play();
		}
	}

	function onMouseLeave() {
		if (!videoPlaying) {
			setMouseOver(false);
		}
	}

	function handlePlay() {
		if (!videoPlaying) {
			previewRef.current.pause();
			previewRef.current.currentTime = 0;
			setMouseOver(false);
			setVideoPlaying(true);
			fullRef.current.play();
		}
	}

	function handleEnd() {
		fullRef.current.pause();
		fullRef.current.currentTime = 0;
		setVideoPlaying(false);
		setMouseOver(false);
	}

	return (
		<ViewTransition>
			<div className="__sage-video-list-item">
				<Row gap={"2rem"}>
					<div
						className={`video ${videoPlaying ? "full-first" : mouseOver ? "preview-first" : "thumbnail-first"}`}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onClick={handlePlay}
					>
						<img
							className="thumbnail"
							src={thumbnail}
						/>
						<video
							className="full"
							ref={fullRef}
							preload="auto"
							autoPlay={false}
							controls
							muted={false}
						>
							<source src={src} />
						</video>
						<video
							className="preview"
							ref={previewRef}
							preload="auto"
							autoPlay={false}
							controls={false}
							muted
						>
							<source src={preview} />
						</video>
					</div>
					<Col gap={"1rem"}>
						<div className="title">{title}</div>
						<div className="description">{description}</div>
						{videoPlaying && (
							<button
								className="close-btn"
								onClick={handleEnd}
							>
								Close
							</button>
						)}
					</Col>
				</Row>
			</div>
		</ViewTransition>
	);
}
