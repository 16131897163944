import { ReactElement, ReactNode } from "react";
import "./Collapsible.scss";

export function Collapsible({
	children,
	visible,
	toggle,
	toggleHandle
}: {
	children: ReactNode | ReactElement;
	visible: boolean;
	toggle?: () => void;
	toggleHandle?: ReactNode | ReactElement;
}) {
	return (
		<div className="__sage-collapsible-container">
			<div onClick={toggle}>{toggleHandle}</div>
			<div className={`collapsible-inner ${visible ? "" : "collapsed"}`}>
				<div className="wrapper">{children}</div>
			</div>
		</div>
	);
}
