import { ReactNode, useState } from "react";
import "./Tabs.scss";

export interface ITabsProps {
	tabs: {
		id: string;
		title: string;
		content: ReactNode;
	}[];
}

export function Tabs({ tabs }: ITabsProps) {
	const [activeTab, setActiveTab] = useState(tabs[0]);

	return (
		<div className="__sage-tabs-container">
			<div className="tabs">
				{tabs.map((tab) => (
					<div
						key={tab.id}
						className={`tab ${tab.id === activeTab.id ? "active" : ""}`}
						onClick={() => setActiveTab(tab)}
					>
						{tab.title}
					</div>
				))}
			</div>
			<div className="active-tab">{activeTab.content}</div>
		</div>
	);
}
