import { useEffect } from "react";
import {
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	ImagePosition,
	LargeCard,
	PageLayout,
	SecondNav,
	Section,
	Spacer
} from "@sage/shared";

export function AnswersYouCanTrustPage() {
	useEffect(() => {
		document.title = "Answers You Can Trust | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/answers-you-can-trust-banner.avif"}
				left={
					<BannerCard
						title={"Answers You Can Trust: The Sage AI Advantage"}
						color={"dark"}
						tagline={"SAGE AI"}
						content={"Grounded Intelligence, Transparent Insights: Experience the Power of Verifiable AI in M&A"}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Answers You Can Trust",
						href: "#answers-you-can-trust"
					},
					{
						text: "Source Attribution",
						href: "#source-attribution"
					},
					{
						text: "Knowledge Grounding",
						href: "#knowledge-grounding"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="answers-you-can-trust">
					<LargeCard
						tagline={"Answers You Can Trust"}
						title={"Answers You Can Trust"}
						subtitle={"Grounded Intelligence for Confident Decision-Making"}
						content={
							"In the fast-paced world of M&A, trust is paramount. Sage AI delivers not just answers, but answers you can rely on.\n\nOur advanced source attribution and knowledge grounding ensure that every insight is traceable, verifiable, and rooted in solid data."
						}
						image={"https://cdn.sageai.dev/website/assets/person-raising-hand-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="source-attribution">
					<LargeCard
						tagline={"Source Attribution"}
						title={"Transparent Source Attribution"}
						subtitle={"Every Insight, Traced to Its Origin"}
						content={
							"Sage AI doesn't just provide answers; it shows its work.\n\nOur robust source attribution system ensures that every piece of information, every insight, and every recommendation is linked back to its original source.\n\nThis transparency allows you to verify the credibility of the information, understand its context, and conduct further research if needed.\n\nWith Sage AI, you're never left wondering where an insight came from."
						}
						image={"https://cdn.sageai.dev/website/assets/two-people-with-docs-desk.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<CardList
						cards={[
							{
								title: "Enhanced Due Diligence",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-1.avif",
								content: "Easily verify and validate information, supporting thorough due diligence processes."
							},
							{
								title: "Increased Confidence",
								image: "https://cdn.sageai.dev/website/assets/discussion-1.avif",
								content: "Make decisions with greater assurance, knowing the exact sources behind each insight."
							},
							{
								title: "Efficient Research",
								image: "https://cdn.sageai.dev/website/assets/research-papers-2.avif",
								content: "Quickly access original sources for deeper dives into specific areas of interest."
							},
							{
								title: "Regulatory Compliance",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-2.avif",
								content: "Support compliance requirements with clear audit trails for information sources."
							}
						]}
					/>
				</Section>
				<Section name="knowledge-grounding">
					<LargeCard
						tagline={"Knowledge Grounding"}
						title={"Knowledge Grounding for Reliable Outputs"}
						subtitle={"AI Insights Anchored in Verified Data"}
						content={
							"Our AI doesn't operate in a vacuum. Sage's knowledge grounding technology ensures that all outputs are firmly anchored in verified, up-to-date information.\n\nBy continuously cross-referencing its responses against a vast, curated knowledge base, Sage AI minimizes the risk of hallucinations or outdated information.\n\nThis means you can trust that the insights you receive are not just plausible, but grounded in real, current data."
						}
						image={"https://cdn.sageai.dev/website/assets/ai-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<Accordian
						sections={[
							{
								id: "1",
								title: "Real-Time Updates",
								content:
									"Our knowledge base is continuously updated to ensure insights are based on the latest information.",
								image: "https://cdn.sageai.dev/website/assets/ai-2.avif"
							},
							{
								id: "2",
								title: "Cross-Referencing",
								content: "AI outputs are verified against multiple sources to ensure accuracy and consistency.",
								image: "https://cdn.sageai.dev/website/assets/ai-3.avif"
							},
							{
								id: "3",
								title: "Domain-Specific Knowledge",
								content: "Our AI is grounded by your firm-specific data, ensuring relevance and accuracy to your deals.",
								image: "https://cdn.sageai.dev/website/assets/ai-4.avif"
							}
						]}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
