import React, { ReactNode } from "react";
import { ViewTransition } from "../ViewTransition";
import "./Ribon.scss";

export function Ribon({ children }: { children: ReactNode }) {
	return (
		<ViewTransition width={"100%"}>
			<div className="__sage-ribon-container">{children}</div>
		</ViewTransition>
	);
}
