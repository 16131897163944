import { ReactNode } from "react";
import { Link } from "../Link";
import { ViewTransition } from "../ViewTransition";
import "./Button.scss";

export function Button({
	children,
	variant,
	href,
	onClick
}: {
	children: ReactNode;
	variant?: string;
	href?: string;
	onClick?: () => void;
}) {
	return (
		<ViewTransition>
			<div className={`__sage-button-container ${variant || "default"}`}>
				{href ? <Link href={href}>{children}</Link> : <button onClick={onClick}>{children}</button>}
			</div>
		</ViewTransition>
	);
}
