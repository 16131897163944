import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, H1, H2, LargeCard, P, PageLayout, SecondNav, Section, Spacer, Text } from "@sage/shared";

export function SecurityPolicyPage() {
	useEffect(() => {
		document.title = "Security & Privacy Policy | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/security-policy-banner.avif"}
				left={
					<BannerCard
						title={"Sage AI Security & Privacy Policy"}
						tagline={"Data Security"}
						content={
							"Our security & privacy policy is publicly available to read, please be sure to check this page for updates.\n\nIf you would like to discus changes to this policy prior to signing our service agreement, please schedule a call with our team."
						}
						actions={<Button href="/get-started">Schedule</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Introduction",
						href: "#introduction"
					},
					{
						text: "User Privacy",
						href: "#user-privacy"
					},
					{
						text: "Security",
						href: "#security"
					},
					{
						text: "Conclusion",
						href: "#conclusion"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Text>
					<Section name="introduction">
						<P>
							Sage AI Technologies, LLC, has provided the following security and privacy guarantees to all users effective
							June 26, 2023.
						</P>
						<H1>Introduction</H1>
						<P>
							Sage AI Technologies, LLC, and all controlling web applications will be referred to as "The Platform." This
							includes all Large Language Models trained and owned by Sage AI Technologies, LLC.
						</P>
					</Section>
					<Section name="user-privacy">
						<H1>User Privacy</H1>
						<H2>Multi-Tenant Data Storage</H2>
						<P>
							All documents uploaded to The Platform (CIMs, pitches, analyst reports, non-public earnings reports, etc.), and
							all user interactions with The Platform (queries submitted, user-generated templates, model responses, etc.),
							are held in a walled multi-tenancy environment. This means that databases and file storage may have multiple
							tenants' (customers') information held in the same tech infrastructure, but each user is only able to access the
							documents and interactions that they have uploaded to or generated within the platform. This is guaranteed with
							tight least access privileges and fully encrypted transit and storage.
						</P>
						<H2>Single-Tenant Proprietary Model Training</H2>
						<P>
							No documents uploaded to The Platform, and no user interactions with The Platform, will be used to train
							multi-tenant models. They will only be used to train single-tenant models with a prior written agreement with
							each tenant. In other words, one customer's data will not be used to train a model that will service a different
							customer. This is a strict guarantee because multi-tenant model training will, by definition, lead to data
							leakage between customers. Whereas, with single-tenant model training, each tenant gets an individualized model
							where only the data that the customer has chosen to share with The Platform will be used for training.
						</P>
						<P>
							Single-tenant model training is not included in the base services offered by The Platform and requires an
							additional written agreement between the user and Sage.
						</P>
					</Section>
					<Section name="security">
						<H1>Security</H1>
						<H2>Encryption</H2>
						<P>
							All documents uploaded to The Platform and all user interactions generated on The Platform are encrypted with
							industry-standard AES 256 encryption at rest and Transport Layer Security in transit. All Application
							Programming Interfaces are secured with SOC2 compliant AWS Cognito user policies. This enables fine-grain
							controls on data access and ensures that public attackers are not able to retrieve data from The Platform.
						</P>
						<H2>Internal Governance</H2>
						<P>
							Employees of Sage AI Technologies, LLC, are given "least access controls" when developing on The Platform. This
							means internal bad actors will not be able to manipulate, access, or delete any tenant proprietary data. All
							feature development is done with internal mock data, not live user data, to eliminate the possibility of a data
							breach.
						</P>
						<P>
							These security controls are in place up to and including current strategic investors and all future investors of
							Sage AI Technologies, LLC.
						</P>
					</Section>
					<Section name="conclusion">
						<H1>Conclusion</H1>
						<P>
							Please direct any questions you may have about this policy to the Co-Founder and Chief Technology Officer, Matt
							Carlson, at matt@sageai.dev. He will be more than happy to discuss or explain any of the points included in our
							guarantees. It is extremely important that each of our users understands this policy and the protections it
							guarantees them.
						</P>
					</Section>
				</Text>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
