import { useEffect } from "react";
import {
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	ImagePosition,
	LargeCard,
	PageLayout,
	SecondNav,
	Section,
	Spacer
} from "@sage/shared";

export function PrivateEquityPage() {
	useEffect(() => {
		document.title = "Private Equity | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/private-equity-banner.avif"}
				left={
					<BannerCard
						title={"AI-Driven Excellence in Private Equity"}
						tagline={"SAGE AI"}
						content={"Transforming PE Deal Flow, Due Diligence, and Portfolio Management"}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Overview",
						href: "#overview"
					},
					{
						text: "Due Diligence",
						href: "#diligence"
					},
					{
						text: "Portfolio Management",
						href: "#portfolio-management"
					},
					{
						text: "Accelerate Deal Flow",
						href: "deal-flow"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="overview">
					<LargeCard
						tagline={"Overview"}
						title={"AI-Powered PE Insights"}
						subtitle={"Transforming Private Equity Decision-Making"}
						content={
							"Sage AI revolutionizes the private equity landscape by delivering deep, actionable insights across the entire deal lifecycle.\n\nFrom initial screening to due diligence and portfolio management, our AI-driven platform enhances your team's capabilities, allowing you to make faster, more informed decisions.\n\nUnlock the power of advanced data analysis and machine learning to stay ahead in a competitive market."
						}
						image={"https://cdn.sageai.dev/website/assets/3-devices-sage.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="diligence">
					<LargeCard
						tagline={"Due Diligence"}
						title={"Streamlining Due Diligence"}
						subtitle={"Uncover Critical Insights Faster"}
						content={
							"Sage AI transforms the due diligence process, allowing your team to analyze vast amounts of data quickly and accurately. Our advanced algorithms sift through financial statements, market reports, and company documents to surface key information and potential red flags"
						}
						image={"https://cdn.sageai.dev/website/assets/athena-close-up-card.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<CardList
						cards={[
							{
								title: "Rapid Data Analysis",
								image: "https://cdn.sageai.dev/website/assets/discussion-1.avif",
								content: "Process thousands of documents in minutes, identifying critical information and trends."
							},
							{
								title: "Risk Identification",
								image: "https://cdn.sageai.dev/website/assets/research-papers-2.avif",
								content: "Automatically flag potential issues and inconsistencies across various data sources."
							},
							{
								title: "Customized Insights",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-1.avif",
								content: "Tailor analysis to your specific investment criteria and focus areas."
							},
							{
								title: "Time Efficiency",
								image: "https://cdn.sageai.dev/website/assets/person-writing-with-computer-2.avif",
								content: "Reduce due diligence time by up to 40%, allowing for faster deal evaluation and execution."
							}
						]}
					/>
				</Section>
				<Section name="portfolio-management">
					<LargeCard
						tagline={"Portfolio Management"}
						title={"Enhancing Portfolio Management"}
						subtitle={"Unlock Qualitative Insights Across Your Investments"}
						content={
							"Sage AI elevates portfolio management by providing deep, context-aware analysis of qualitative data from both internal and external sources. Our LLM RAG platform processes vast amounts of unstructured information, offering nuanced insights to inform your strategic decisions."
						}
						image={"https://cdn.sageai.dev/website/assets/home-page-dealstream-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<Accordian
						sections={[
							{
								id: "1",
								title: "Comprehensive Information Retrieval",
								content:
									"Quickly access and synthesize relevant information from internal reports, market analyses, and company communications.",
								image: "https://cdn.sageai.dev/website/assets/two-people-with-docs-desk.avif"
							},
							{
								id: "2",
								title: "Contextual Understanding",
								content:
									"Leverage our AI's ability to grasp complex relationships and nuances in qualitative data, providing more informed perspectives on portfolio companies.",
								image: "https://cdn.sageai.dev/website/assets/people-writing-3.avif"
							},
							{
								id: "3",
								title: "Strategic Decision Support",
								content:
									"Generate AI-powered summaries and recommendations based on in-depth analysis of qualitative data, supporting more informed strategic choices.",
								image: "https://cdn.sageai.dev/website/assets/people-reading-documents-1.avif"
							}
						]}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Unlock the Power of Your Portfolio Data"}
							content={
								"Discover how Sage AI can transform your approach to portfolio management.\n\nSchedule a call today and see how our AI-driven insights can enhance your decision-making process."
							}
							actions={<Button href={"/get-started"}>Get Started</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="deal-flow">
					<LargeCard
						tagline={"Accelerate Deal Flow"}
						title={"Accelerate Deal Flow"}
						subtitle={"Streamline Deal Evaluation with AI-Powered Insights"}
						content={
							"Sage AI revolutionizes deal sourcing and evaluation by rapidly processing and analyzing vast amounts of qualitative data. Our platform helps you identify promising opportunities faster and make more informed decisions throughout the deal process."
						}
						image={"https://cdn.sageai.dev/website/assets/ib-page-docs-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<Accordian
						sections={[
							{
								id: "1",
								title: "Efficient Deal Screening",
								content:
									"Quickly assess potential deals by analyzing company descriptions, market reports, and industry news using our advanced natural language processing.",
								image: "https://cdn.sageai.dev/website/assets/dealstream-zoomed.avif"
							},
							{
								id: "2",
								title: "In-Depth Company Analysis",
								content:
									"Gain comprehensive insights into target companies by synthesizing information from various sources, including deep web crawls and internal documents.",
								image: "https://cdn.sageai.dev/website/assets/3-devices-red.avif"
							},
							{
								id: "3",
								title: "Customized Deal Insights",
								content:
									"Generate tailored reports and summaries focused on your specific investment criteria and areas of interest, saving time in the evaluation process.",
								image: "https://cdn.sageai.dev/website/assets/home-page-templates-1.avif"
							}
						]}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Sage AI Data Security",
							content:
								"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
							href: "/security/how-we-secure-your-data",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
						},
						{
							title: "Interactive Data Room Analysis",
							image: "https://cdn.sageai.dev/website/assets/docs-project-files-close-up-1.avif",
							content:
								"Sage AI doesn't just read your data room; it understands it. Our platform allows you to interactively explore and query your data, uncovering valuable insights and connections that might otherwise be missed.",
							href: "/solutions/information-retrieval"
						},
						{
							title: "Supercharged Deliverable Creation",
							image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
							content:
								"The RAG-enabled LLM can generate tailored pitch materials that resonate with the specific needs and challenges of each prospect.\n\nThis includes personalized slides, and potential outcomes that demonstrate a deep understanding of the prospect's business and industry.",
							href: "/get-started"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
