import { useEffect } from "react";
import {
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	Col,
	ImagePosition,
	LargeCard,
	PageLayout,
	SecondNav,
	Section,
	ServicesCards,
	Spacer,
	VideoCard
} from "@sage/shared";

export function InvestmentBankingPage() {
	useEffect(() => {
		document.title = "Investment Banking | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/ib-banner.avif"}
				left={
					<BannerCard
						title={"Sage AI Augments Each Stage of the Deal Cycle"}
						tagline={"Investment Banking"}
						content={
							"Sage AI was meticulously designed with a deep understanding of the daily challenges faced by investment banking staff members"
						}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={
					<VideoCard
						thumbnail={"https://cdn.sageai.dev/website/assets/home-page-thumbnail.avif"}
						video={"https://cdn.ccm.vc/sage/videos/Sage-AI-Explained.mp4"}
					/>
				}
			/>
			<SecondNav
				links={[
					{
						text: "Prospect Pitch (Bake-Off)",
						href: "#bake-off"
					},
					{
						text: "Initial Due Diligence",
						href: "#initial-due-diligence"
					},
					{
						text: "Marketing Material Creation",
						href: "#marketing-material-creation"
					},
					{
						text: "Buyer Diligence",
						href: "#buyer-diligence"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="bake-off">
					<LargeCard
						tagline={"Prospect Pitch"}
						title={"Tailor Your Bake-Off Presentation in Minutes, Not Hours"}
						subtitle={"Effortlessly Adapt Your Bake-Off for Every Unique Pitch"}
						content={
							"Our cutting-edge LLM-RAG system allows you to adjust your bake-off presentations with unprecedented speed and precision. At this early critical junction, a great impression is necessary and making that impression is time consuming.\n\nUsing Sage AI, you can take your existing presentation that works so well, and adjust it for every client based on the information you have about them."
						}
						image={"https://cdn.sageai.dev/website/assets/cim-3-devices.png"}
						imagePosition={ImagePosition.Right}
					/>
					<LargeCard
						content={
							"Whether you're adjusting for different prospects, industries, or deal specifics, Sage AI empowers you to create compelling, customized presentations that resonate with each unique audience.\n\nSay goodbye to hours of manual adjustments and hello to pitch-perfect bake-offs that give you the competitive edge in every M&A opportunity. With Sage AI, you're always prepared to serve up a winning presentation."
						}
						image={"https://cdn.sageai.dev/website/assets/cim-red.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Start using Sage for Prospect Pitching"}
							content={
								"Pitch more by using Sage to generate highly accurate bake-off pitches tailored to your prospect, based on previous pitch decks."
							}
							actions={<Button href={"/get-started"}>Get Started</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="initial-due-diligence">
					<LargeCard
						tagline={"Initial Due Diligence"}
						title={"From Scattered Facts to Cohesive Knowledge"}
						subtitle={
							"Predictive Due Diligence: Sage AI anticipates crucial buyer inquiries, ensuring comprehensive answers from day one and minimizing follow-up requests."
						}
						content={
							"Our advanced AI system doesn't just gather data—it anticipates critical buyer questions, ensuring comprehensive information collection from the start.\n\nBy leveraging machine learning and industry expertise, Sage AI identifies and addresses potential information gaps before they become roadblocks."
						}
						image={"https://cdn.sageai.dev/website/assets/home-page-dealstream-1.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<LargeCard
						content={
							"This proactive approach significantly reduces follow-up requests, accelerates the due diligence process, and allows deal teams to focus on strategic analysis rather than repetitive data gathering.\n\nWith Sage AI, you'll enter negotiations armed with a complete, well-organized information package that addresses buyers' key concerns, setting the stage for smoother, faster deal progression."
						}
						image={"https://cdn.sageai.dev/website/assets/home-page-vault-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="marketing-material-creation">
					<LargeCard
						tagline={"Marketing Material Creation"}
						title={"Streamlined Processes and Precise Deliverable Creation"}
						subtitle={"Deliverables done faster and better, while maintaining industry leading quality"}
						content={
							"Sage AI revolutionizes the creation of M&A marketing materials, transforming what was once the most time-consuming aspect of deal-making into a streamlined, efficient process.\n\nBy automating the tedious aspects of document creation, Sage AI allows your team to focus on strategy and deal execution.\n\nExperience a significant reduction in turnaround times without compromising on the depth and accuracy of your marketing materials."
						}
					/>
					<Accordian
						sections={[
							{
								id: "teasers",
								title: "Teasers",
								content:
									"Teasers are your deal's first impression, and with Sage AI, you'll make it count. Our advanced system rapidly distills key information from your data room, crafting compelling teasers that capture attention and spark interest.\n\nWithin minutes, you'll have a polished, professional teaser that effectively communicates value proposition without revealing sensitive details.",
								image: "https://cdn.sageai.dev/website/assets/ib-page-docs-1.avif"
							},
							{
								id: "CIPs",
								title: "CIPs",
								content:
									"Sage AI transforms the creation of CIMs/CIPs from a time-consuming task into a streamlined, efficient process. In a fraction of the typical time, Sage AI produces comprehensive, well-structured CIMs and CIPs that tell your client's company's story compellingly and accurately.\n\nThese documents are automatically tailored to highlight your unique value propositions, growth opportunities, and competitive advantages.\n\nWith Sage AI, you'll deliver polished, professional-grade CIMs and CIPs that not only save you countless hours but also present your client's business in the best possible light to prospective buyers.",
								image: "https://cdn.sageai.dev/website/assets/ib-page-cip-1.avif"
							},
							{
								id: "MPs",
								title: "Management Presentations",
								content:
									"Sage AI turns what is often a lengthy, iterative process into a swift and efficient task. Sage AI crafts a narrative that showcases your Client’s company vision, strategy, and growth potential, ensuring that every slide tells a part of their success story.\n\nSage streamlines the entire presentation development process. The result is a polished, professional Management Presentation that not only saves your team valuable time but also ensures consistency with your other deal documents.",
								image: "https://cdn.sageai.dev/website/assets/ib-page-cip-2.avif"
							}
						]}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Start using Sage for Marketing Materials"}
							content={
								"Create precise marketing materials tailored to your firm faster, while maintaining your high quality expectations."
							}
							actions={<Button href={"/get-started"}>Get Started</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="buyer-diligence">
					<LargeCard
						tagline={"Buyer Diligence"}
						title={"Trustworthy Results Every Time"}
						subtitle={"Extracting needle-in-a-haystack answers from your data room, instantly"}
						content={
							"Our system expertly sifts through vast amounts of financial documents, contracts, and operational data to provide accurate, comprehensive answers to even the most intricate buyer queries.\n\nBy leveraging Sage AI, deal teams can significantly reduce response times, maintain consistency across multiple rounds of questions, and free up valuable resources for strategic decision-making.\n\nWhether you're dealing with financial projections, regulatory compliance issues, or operational intricacies, Sage AI empowers your team to deliver thorough, data-driven responses that instill confidence in potential buyers and accelerate the deal process."
						}
						image={"https://cdn.sageai.dev/website/assets/athena-close-up-card.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
			</PageLayout>
			<Section name="next-steps">
				<Col gap={"2rem"}>
					<LargeCard
						tagline={"Next Steps"}
						title={"Ready To Get Started?"}
					/>
					<CardList
						cards={[
							{
								title: "",
								image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
								content: "Schedule a call with our team to get set up with a two week free trial.",
								href: "/get-started"
							}
						]}
					/>
					<LargeCard title={"Still want to learn more?"} />
					<ServicesCards />
				</Col>
			</Section>
		</section>
	);
}
