import { Link } from "../Link";
import "./Footer.scss";

export function Footer() {
	return (
		<div className="__sage-footer-container">
			<div className="row">
				<div className="col">
					<div className="title">Solutions</div>
					<Link href={"/solutions/deliverable-creation"}>Deliverable Creation</Link>
					<Link href={"/solutions/information-retrieval"}>Information Retrieval</Link>
					<Link href={"/solutions/investment-banking"}>Investment Banking</Link>
					<Link href={"/solutions/private-equity"}>Private Equity</Link>
					<Link href={"/solutions/equity-research"}>Equity Research</Link>
				</div>
				<div className="col">
					<div className="title">Security</div>
					<Link href={"/security/how-we-secure-your-data"}>How we secure your data</Link>
					<Link href={"/security/security-and-privacy-policy"}>Security & privacy policy</Link>
					<Link href={"/security/governance"}>Governance</Link>
				</div>
				<div className="col">
					<div className="title">Responsible AI</div>
					<Link href={"/responsible-ai/overview"}>Overview</Link>
					<Link href={"/responsible-ai/answers-you-can-trust"}>Answers you can trust</Link>
					<Link href={"/responsible-ai/data-training-and-privacy"}>Data training & Privacy</Link>
					<Link href={"/responsible-ai/principles-and-approach"}>Principles & Approach</Link>
				</div>
				<div className="col">
					<div className="title">Company</div>
					<Link href={"/get-started"}>Contact Us</Link>
				</div>
			</div>
		</div>
	);
}
