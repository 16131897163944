import { useEffect } from "react";
import { Banner, BannerCard, Button, CardList, ImagePosition, LargeCard, PageLayout, SecondNav, Section, Spacer } from "@sage/shared";

export function PrinciplesAndApproachPage() {
	useEffect(() => {
		document.title = "Principles & Approach | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/approach-banner.avif"}
				left={
					<BannerCard
						title={"Engineering Excellence in AI for Investment Banking"}
						color={"dark"}
						tagline={"SAGE AI"}
						content={
							"Where Financial Expertise Meets Cutting-Edge Technology: Discover Sage AI's Commitment to Robust, Scalable, and Ethical AI Development"
						}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Our Approach",
						href: "#approach"
					},
					{
						text: "Architecture & Scalability",
						href: "#architecture"
					},
					{
						text: "Quality Assurance",
						href: "#quality-assurance"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="approach">
					<LargeCard
						tagline={"Our Approach"}
						title={"Principles & Approach: Building AI for Investment Banking Excellence"}
						subtitle={"Where Financial Acumen Meets Cutting-Edge Technology"}
						content={
							"At Sage, we combine deep investment banking knowledge with state-of-the-art AI development practices.\n\nOur approach ensures that we deliver not just powerful, but reliable, secure, and ethically sound AI solutions tailored for the financial sector's unique challenges."
						}
						image={"https://cdn.sageai.dev/website/assets/dev-team-2.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="architecture">
					<LargeCard
						tagline={"Architecture"}
						title={"Robust Architecture & Scalability"}
						subtitle={"Engineered for Performance and Growth"}
						content={
							"Our AI systems are built on a foundation of scalable, distributed architectures designed to handle the massive data volumes and complex computations inherent in investment banking.\n\nWe employ cloud-native technologies and microservices architecture to ensure high availability, fault tolerance, and the ability to scale seamlessly with your needs."
						}
						image={"https://cdn.sageai.dev/website/assets/dev-team-3.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<CardList
						cards={[
							{
								title: "Distributed Computing",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-1.avif",
								content:
									"Leveraging distributed compute technologies for big data processing and distributed machine learning."
							},
							{
								title: "Containerization",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-3.avif",
								content:
									"Using containerization for efficient deployment and scaling of our AI services, and for test reproducability."
							},
							{
								title: "Load Balancing",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-2.avif",
								content:
									"Implementing advanced load balancing techniques to ensure optimal performance under varying workloads."
							},
							{
								title: "Automated Failover",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-4.avif",
								content: "Employing redundancy and automated failover mechanisms to maintain high availability."
							}
						]}
					/>
					<LargeCard
						title={"Advanced NLP and Machine Learning"}
						subtitle={"Cutting-Edge AI Tailored for Finance"}
						content={
							"Our AI models leverage the latest advancements in Natural Language Processing (NLP) and machine learning, specifically tuned for the nuances of financial language and data. We employ transformer-based models and continual learning techniques to ensure our AI stays at the forefront of financial analysis capabilities."
						}
						image={"https://cdn.sageai.dev/website/assets/dev-team-5.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="quality-assurance">
					<LargeCard
						tagline={"Quality Assurance"}
						title={"Rigorous Testing and Validation"}
						subtitle={"Ensuring Reliability in High-Stakes Environments"}
						content={
							"We understand the critical nature of investment banking decisions. Our development process includes comprehensive testing regimes, from unit tests to integration tests, stress tests, and A/B testing in production-like environments.\n\nWe employ continuous integration and deployment (CI/CD) practices to ensure rapid, yet safe, iterations, with AI model regression testing to validate that new state of the art models pass our internal benchmarks."
						}
						image={"https://cdn.sageai.dev/website/assets/dev-team-4.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<CardList
						cards={[
							{
								title: "Automated Testing",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-5.avif",
								content: "Extensive use of automated test suites for consistent quality assurance."
							},
							{
								title: "Canary Deployments",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-6.avif",
								content: "Gradually rolling out updates to minimize risk and quickly catch potential issues"
							},
							{
								title: "Regression Testing",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-7.avif",
								content:
									"We automatically benchmark new state of the art models against representative M&A workloads before adopting, ensuring you always have the most powerful models."
							},
							{
								title: "Ongoing Monitoring",
								image: "https://cdn.sageai.dev/website/assets/cloud-compute-8.avif",
								content:
									"Implementing advanced logging and monitoring for real-time performance tracking and anomaly detection."
							}
						]}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Security Through Obfuscation",
							content:
								"Sage cannot publicly disclose methodologies related to cryptography or identity management, as it exposes us to a potential security incident.\n\nIf you or a member of your team would like to learn more about these details, please schedule a call with our team.",
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
							cta: "Schedule"
						},
						{
							title: "Industry Security Standards",
							content:
								'Sage follows the AWS "Well Architected Framework", a battle tested system for building secure and reliable infrastructure.\n\nIf you would like to learn more about AWS\'s security framework, take a look at the white paper.',
							href: "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/security-pillar/wellarchitected-security-pillar.pdf?did=wp_card&trk=wp_card",
							image: "https://cdn.sageai.dev/website/assets/aws-cloud-sec-banner.avif"
						},
						{
							title: "What's New With Cloud Security?",
							content:
								"If you would like to see what's new with the state of the art of cloud security, and learn about the state of the art policies being implemented continuously, you can take a look at the AWS announcements.",
							href: "https://aws.amazon.com/about-aws/whats-new/security_identity_and_compliance/?whats-new-content.sort-by=item.additionalFields.postDateTime&whats-new-content.sort-order=desc&awsf.whats-new-products=*all",
							image: "https://cdn.sageai.dev/website/assets/whats-new-with-aws-sec.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
