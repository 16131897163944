import { useState } from "react";
import { Collapsible } from "../Collapsible";
import { FaIcon } from "../FaIcon";
import { Link } from "../Link";
import "./Dropdown.scss";

export interface IDropdown {
	title: string;
	size?: string;
	position?: string;
	options: {
		text: string;
		href: string;
		icon?: string;
		color?: string;
	}[];
}

export function Dropdown({ title, options, size, position }: IDropdown) {
	const [active, setActive] = useState<boolean>(false);

	return (
		<div
			className={`__sage-dropdown-container ${size} ${position}`}
			onClick={() => setActive((e) => !e)}
			onMouseLeave={() => setActive(false)}
			onMouseOver={() => setActive(true)}
		>
			<div className="dropdown-content">
				{title}
				<img
					alt="img-icon"
					src={`https://cdn.ccm.vc/sage/icons/material-arrow-down.svg`}
					width={12}
				/>
			</div>
			<div className={`dropdown-inner ${active ? "open" : "closed"}`}>
				<Collapsible visible={active}>
					{options.map((option) => (
						<Option
							key={option.text}
							text={option.text}
							href={option.href}
							icon={option.icon}
							color={option.color}
						/>
					))}
				</Collapsible>
			</div>
		</div>
	);
}

function Option({ text, href, icon, color }: { text: string; href: string; icon?: string; color?: string }) {
	return (
		<div className="__sage-dropdown-option">
			<Link
				href={href}
				size="sm"
			>
				{icon && (
					<FaIcon
						icon={icon}
						color={color}
						animation="fa-bounce"
						direction="row"
					>
						{text}
					</FaIcon>
				)}
			</Link>
		</div>
	);
}
