import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./Link.scss";

export function Link({ href, children, size }: { href: string; children: ReactNode; size?: string }) {
	return (
		<div className={`__sage-link-container ${size}`}>
			{href.includes("#") ? (
				<a href={href}>{children}</a>
			) : (
				<RouterLink
					to={href}
					target={href.includes("https") ? "_blank" : undefined}
					preventScrollReset={false}
				>
					{children}
				</RouterLink>
			)}
		</div>
	);
}
