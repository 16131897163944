import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import "./Layout.scss";

export function Layout() {
	const location = useLocation();
	const [params, setParams] = useSearchParams();
	const posthog = usePostHog();

	useEffect(() => {
		const email = params.get("e");
		if (email) {
			setParams({});
			posthog.capture("capture_email", {
				$set: {
					email
				}
			});
		}

		if (!location.hash) {
			document.documentElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
	}, [location.pathname]);

	return (
		<div>
			<Header />
			<div className="__sage-body">
				<Outlet />
			</div>
			<Footer />
		</div>
	);
}
