import { ReactNode } from "react";
import { Anchor } from "../Anchor";

export function Section({ name, children }: { name?: string; children: ReactNode }) {
	return (
		<>
			{name && <Anchor name={name} />}
			{children}
		</>
	);
}
