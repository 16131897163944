import { ReactNode } from "react";
import "./PageLayout.scss";

export function PageLayout({ children, size }: { children: ReactNode; size?: string }) {
	return (
		<div className={`__sage-page-layout-container ${size}`}>
			<div className="page-content">{children}</div>
		</div>
	);
}
