import { IOutlinkCardProps, OutlinkCard } from "../OutlinkCard";
import "./CardList.scss";

export interface ICardListProps {
	cards?: IOutlinkCardProps[];
}

export function CardList({ cards }: ICardListProps) {
	return (
		<div className="__sage-card-list-container">
			{cards?.map((card, idx) => (
				<OutlinkCard
					{...card}
					key={idx}
				/>
			))}
		</div>
	);
}
