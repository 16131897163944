import { ReactNode } from "react";
import "./Banner.scss";

export function Banner({
	src,
	left,
	right,
	center,
	size,
	border
}: {
	src: string;
	left?: ReactNode;
	right?: ReactNode;
	center?: ReactNode;
	size?: string;
	border?: string;
}) {
	return (
		<div
			className={`__sage-banner-container ${size}`}
			style={{ backgroundImage: `url("${src}")`, border }}
		>
			{left && <div className="col">{left}</div>}
			{center && <div className="col">{center}</div>}
			{right && <div className="col">{right}</div>}
		</div>
	);
}
